
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-zoom-viewer {
        $this: &;

        height: 100%;
        width: 100%;
        overflow: scroll;

        &__img-container {
            position: relative;
            background-color: $nu-secondary;
            width: 100%;
            padding-top: 150%;
            cursor: pointer;
        }

        &__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &__thumbnails {
            display: none;
            flex-direction: column;
            justify-content: center;
        }

        &__thumbnail-container {
            // container size is the thumbnail size + 4px of height/width so there's room for the 2px border
            height: 100px;
            width: 68px;
            margin-bottom: $nu-spacer-1;
            position: relative;

            &:hover,
            &--active {
                #{$this}__thumbnail {
                    border-color: $nu-white;
                }
            }

            &:hover:not(&--active) {
                #{$this}__thumbnail {
                    border-width: 1px;
                }
            }

            &--active {
                #{$this}__thumbnail {
                    border-width: 2px;
                }
            }
        }

        &__thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;

            height: 96px;
            width: 64px;
            border: 1px solid transparent;
            cursor: pointer;
            box-sizing: content-box;
            transition: border 0.2s ease;
        }

        &__thumbnails--fixed {
            @include breakpoint(medium) {
                position: fixed;
                display: flex;
                top: 0;
                bottom: 0;
                left: $nu-spacer-3;
                z-index: map-get($zindex, modal);
            }
        }

        &__images--row {
            #{$this}__thumbnail-container {
                width: 100%;
                height: auto;

                &:hover,
                &--active {
                    #{$this}__thumbnail {
                        border-color: $nu-primary;
                        border-width: 2px;
                    }
                }
            }

            #{$this}__thumbnail {
                position: relative;
                width: 100%;
                height: auto;
                border: 2px solid transparent;
            }

            @include breakpoint(large) {
                margin-top: $nu-spacer-5;

                #{$this}__thumbnails {
                    position: sticky;
                    top: $nu-spacer-5;
                    display: flex;
                }
            }
        }
    }
